// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  sirketadi: 'EMB BİLİŞİM VE GÜVENLİK TEKNOLOJİLERİ LTD. ŞTİ.',
  sirketlogo: 'https://montajcenter.com/img/logo/logo.png',
  sirketadres:"PERPA TİCARET MERKEZİ A BLOK KAT:9/1157 HALİL RIFATPAŞA MAHALLESİ YÜZER HAVUZ SOKAK ŞİŞLİ / İSTANBUL ",
  sirkettel:"TEL: 0212 222 0 155     0850 554 0 155",
  sirketemail:"info@hakanelektronik.info",
  website:"https://montajcenter.com/",
  instagram:"#",
  facebook:"#",

  apikey:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJLRVkiOnsia3VydW1hcGlrZXkiOiIyMEZCQzE4Ni01N0U1LTRBMzctOUZCNy0zNTI0RDE4RUFBQUMiLCJzZXJ2aXNuYW1lIjoiQ0lIQVpTT1JHVSJ9fQ.li1Rg9gNEVDRovYp_PsqXZa5vq2UL6HE58gBuAhshPM',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
