import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataModel } from '../Model/dataModel';
import { TalepService } from '../talep.service';
import { NgRecaptcha3Service } from 'ng-recaptcha3';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  sirketadi = environment.sirketadi;
  sirketlogo = environment.sirketlogo;
  sirketadres = environment.sirketadres;
  sirkettel = environment.sirkettel;
  sirketemail = environment.sirketemail;
  website = environment.website;
  instagram = environment.instagram;
  facebook = environment.facebook;

  form: FormGroup;
  loading = false;
  submitted = false;
  recaptcha3_pagekey:string ="6LcFIvsaAAAAAKPauS07_HWFnvCPgCmz1PZel1bO";
  dataModel: DataModel;
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService, 
    private talepService: TalepService, 
    private recaptcha3: NgRecaptcha3Service
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      ServisNo: ['', Validators.required],
      TelefonNo: ['', Validators.required],
    });

    this.recaptcha3.init(this.recaptcha3_pagekey);
  }

  get f() {
    return this.form.controls;
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    // generate new token
    this.recaptcha3.getToken().then(
      (token) => {
        const formData = this.form.value;
        formData.recaptchaToken = token;

        let add = Object.assign({}, this.form.value);
        console.log(add);

        this.talepService.Sorgu(add).subscribe(
          (res) => {
           
            if (res.BasariliMi) {
              this.dataModel = res.Veri;
              console.log( this.dataModel);
              this.toastrService.info('Kayıt Listelendi', 'Başarılı!');
            } else {
              this.toastrService.error(res.Mesaj, 'Dikkat!');
              this.loading = false;
            }
          },
          (err) => {
            console.log(err);
            this.toastrService.error(err.Mesaj, 'Dikkat!');
            this.loading = false;
          }
        );
      },
      (error) => {
        this.toastrService.error("recaptcha Doğrulanamadı", 'Dikkat!');
        console.log(error);
        
      }
    );
  }
}
